import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import { Copyright, Layout } from '@components/common'
import { useTranslation } from '@contexts/translation'
import {
  WelcomeCard, OperationsCard, ManagerTasksTable,
  ManagerRecapTable, LotsOverviewCard, RentOverviewCard,
  LeaseOverviewCard, ManageOverviewCard
} from '@components/managerDashboard'
import { MapView } from '@components/operations'
import { numberWithSpaces } from '@utils/numberWithSpaces'
import { useFetchData } from '@api/fetchData'
import Link from 'next/link'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    textAlign: 'center',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  map: {
    zIndex: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  link: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  metrics: {
    marginTop: theme.spacing(2),
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1'
  },
  tenantName: {
    fontWeight: '700',
  },
  operationTenant: {
    fontWeight: '300',
  },
  root: {
    height: '100%',
  },
  defaultLabel: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
  },
  landlordCompany: {
    fontSize: '14px',
    lineHeight: '1',
    fontWeight: '700',
  },
  landlord: {
    fontSize: '14px',
    lineHeight: '1',

  },
  mail: {
    fontSize: '14px',
    lineHeight: '1',
    color: theme.palette.grey[500],
  },
}))




export default function ManagerDashboardView() {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()


  const fetchUrl = `/stats/indicators/occupancy/`
  const { data, isLoading, isError } = useFetchData(fetchUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })


  const tenants_columns = [
    {
      field: 'display',
      headerName: dictionary['name'],
      headerClassName: 'round_corner_start_no_shadow',
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => (
        <Link href={`/tenants/${params.row.id}`}>
          <a className={classes.link}>{params.row.display}</a>
        </Link>
      ),
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      headerClassName: 'round_corner_end_no_shadow',
      flex: 1,
      minWidth: 90,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
  ]
  const landlords_columns = [
    {
      field: 'display',
      headerName: dictionary['name'],
      headerClassName: 'round_corner_start_no_shadow',
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => (
        <Link href={`/landlords/${params.row.id}`}>
          <a className={classes.link}>{params.row.display}</a>
        </Link>
      ),
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      headerClassName: 'round_corner_end_no_shadow',
      flex: 1,
      minWidth: 90,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
  ]
  const suppliers_columns = [
    {
      field: 'display',
      headerName: dictionary['name'],
      headerClassName: 'round_corner_start_no_shadow',
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: (params) => (
        <Link href={`/suppliers/${params.row.id}`}>
          <a className={classes.link}>{params.row.display}</a>
        </Link>
      ),
    },
    {
      field: 'balance',
      headerName: dictionary['balance'],
      headerClassName: 'round_corner_end_no_shadow',
      flex: 1,
      minWidth: 90,
      type: 'number',
      sortable: false,
      valueFormatter: (params) => numberWithSpaces(params.value)
    },
  ]

  return (
    <Layout title={dictionary['dashboard']}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container justify="center" spacing={2}>
          {/* Welcome Card + Operation Slider */}
          <Grid item xs={12} md={8}>
            <WelcomeCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <OperationsCard />
          </Grid>

          {/* Overview Cards */}
          <Grid item xs={12} md={4}>
            <LotsOverviewCard data={data} />
          </Grid>
          <Grid item xs={12} md={4}>
            <RentOverviewCard data={data} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LeaseOverviewCard data={data} />
          </Grid>

          {/* Graph Recap */}
          {false && data && data.operation_count !== 0 &&
            <Grid item xs={12} md={12}>
              <ManageOverviewCard data={data} />
            </Grid>
          }

          {/* Landlord / Tenants / Maintenance */}
          <Grid item xs={12} md={4}>
            <ManagerRecapTable
              title={dictionary['tenants']}
              fetchUrl={`/tenants/?page_size=999`}
              subtitle={dictionary['tenants_list_commercial']}
              color={theme.palette.secondary.main}
              columns={tenants_columns}
              imgSrc="/static/images/Tenants.png"
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <ManagerRecapTable
              title={dictionary['suppliers']}
              subtitle={dictionary['budgets_maintenance']}
              fetchUrl={`/suppliers/?page_size=999`}
              color={theme.palette.warning.main}
              columns={suppliers_columns}
              imgSrc="/static/images/Suppliers.png"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ManagerRecapTable
              title={dictionary['navigation_landlords']}
              subtitle={dictionary['active_landlords']}
              fetchUrl={`/landlords/?page_size=999`}
              columns={landlords_columns}
              imgSrc="/static/images/Budget.png"
            />
          </Grid>

          {/* MapView + Tasks table */}
          <Grid item xs={12} md={8}>
            <Card className={classes.root}>
              <MapView className={classes.map} isDashboard center={{ lat: 48.8685708, lng: 2.3302606 }} />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <ManagerTasksTable />
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
