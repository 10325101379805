import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Card } from '@material-ui/core';
import { useTranslation } from '@contexts/translation';
import { useFetchData } from '@api/fetchData'
import Carousel from 'react-material-ui-carousel'
import ArrowRightOutline from '@iconify/icons-eva/arrow-right-outline';
import ArrowLeftOutline from '@iconify/icons-eva/arrow-left-outline';
import { Icon } from '@iconify/react';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  carousel: {
    height: '100%',
    '& div:nth-child(3)': {
      right: '40px',
      left: 'unset',
    },
    '& div': {
      '& button': {
        opacity: 1
      },
    },
    '& .CarouselItem': {
      height: '100%',
      '& div': {
        height: '100%',
      },
    }
  },
  arrowContainer: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  arrow: {
    color: 'white',
  },
  carouselContainer: {
    height: '100%',
    padding: theme.spacing(2),
    background: 'rgba(0,0,0,0.4)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  infoContainer: {
    height: 'auto !important',
    display: 'flex',
    flexDirection: 'column'
  },
  prevIcon: {
  }
}));


function OperationsCard() {
  const classes = useStyles();
  const { dictionary } = useTranslation()
  const { data, isLoading, isError } = useFetchData(
    `/operations/?is_archived=false`,
    null,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  )


  return (
    <Carousel
      className={classes.carousel}
      fullHeightHover={false}
      navButtonsAlwaysVisible={data && data.lenght > 0}
      NavButton={({ onClick, className, style, next, prev }) => {
        return (
          <Button onClick={onClick} className={clsx(prev && classes.prevIcon, className)} style={style}>
            {next && <Icon className={classes.arrow} icon={ArrowRightOutline} width={40} height={40} />}
            {prev && <Icon className={classes.arrow} icon={ArrowLeftOutline} width={40} height={40} />}
          </Button>
        )
      }}
      navButtonsWrapperProps={{
        style: {
          top: '0',
          height: 'auto',
        }
      }}
      navButtonsProps={{
        style: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          margin: 0,
        }
      }}
      indicatorContainerProps={{
        style: {
          marginTop: '-30px',
        }
      }}
    >
      {
        data && data.results.length > 0 ? data.results.map((operation, i) => (
          <CarouselItem
            key={operation.id}
            photo={operation.photo}
            name={operation.name}
            address={operation.address}
          />
        )
        )
          :
          <Card
            sx={{
              height: '100%',
              background: 'url(/static/images/placeholder_building.jpg)',
              backgroundSize: 'cover',
            }}
          >
            <div className={classes.carouselContainer}>
              <div className={classes.infoContainer}>
                <Typography
                  variant="body1"
                  sx={{
                    color: 'white',
                    textTransform: 'uppercase',
                    pb: 0
                  }}
                >
                  {dictionary['your_first_operation']}
                </Typography>
              </div>
            </div>
          </Card>
      }
    </Carousel>
  );
}

function CarouselItem({ photo, name, address }) {
  const classes = useStyles();
  const { dictionary } = useTranslation()
  return (
    <Card
      sx={{
        height: '100%',
        background: photo ? `url(${photo})` : 'url(/static/images/placeholder_building.jpg)',
        backgroundSize: 'cover',
      }}
    >
      <div className={classes.carouselContainer}>
        <div className={classes.infoContainer}>
          <Typography
            variant="body1"
            sx={{
              color: 'white',
              textTransform: 'uppercase',
              pb: 0
            }}
          >
            {dictionary['your_operations']}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: 'white',
              fontWeight: 700,
              pb: 0
            }}
          >
            {name}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: 'white',
              pb: { xs: 3, xl: 5 }
            }}
          >
            {address}
          </Typography>
        </div>
      </div>
    </Card>
  )
}

export default OperationsCard;
