import React from 'react'
import { merge } from 'lodash';
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import { Card, Box, CardHeader, Typography } from '@material-ui/core'
import dynamic from 'next/dynamic'
const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })
import { fNumber } from '@utils/formatNumber'
import ApexChartsOption from '@components/dashboard/charts/ApexChartsOption'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    fontSize: '14px',
    color: theme.palette.grey[500],
  },
  leasesContainer: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },
  overviewInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  leaseNumber: {
    fontWeight: '300',
    paddingLeft: theme.spacing(1)
  },
  leaseSigned: {
    color: theme.palette.grey[500],
    fontWeight: "500",
    fontSize: '12px',
  },
}))

export default function LeaseOverviewCard({
  data
}) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()
  let info = data && Object.values(data.current_leases_trimesters)
  const chartData = [{ data: info && info.length > 0 ? info : [] }];

  const chartOptions = merge(ApexChartsOption(), {
    chart: { animations: { enabled: true }, sparkline: { enabled: true } },
    // stroke: { width: 2, colors: ['#499a5b'] },
    tooltip: {
      x: {
        show: true,
        formatter: (seriesName) => dictionary[`q${seriesName}`],
      },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: function (seriesName) {
            return '';
          }
        }
      },
      markers: { show: true, size: 0 },
      xaxis: {
        type: 'string'
      }, 
      stroke: {
        curve: 'straight',
        width: 2,
        colors: ['#499a5b']
      }
    },
  });

  return (
    <Card className={classes.container}>
      <div className={classes.titleContainer}>
        <CardHeader
          title={
            <>
              <Box>{dictionary['my_leases']}</Box>
              <Typography className={classes.description}>
                {dictionary['current_leases']}
              </Typography>
            </>
          }
        />
      </div>
      <div className={classes.leasesContainer}>
        <div className={classes.overviewInfoContainer}>
          <Typography variant="h1" className={classes.leaseNumber}>
            +{data && data.lease_signatures}
          </Typography>
          <Typography variant="body1" className={classes.leaseSigned}>
            {dictionary['leases_signed']} 2022
          </Typography>
        </div>
        <ReactApexChart
          type="area"
          series={chartData}
          options={chartOptions}
          width={180}
          height={100}
        />
      </div>

    </Card>
  )
}
