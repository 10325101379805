import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import { Card, Box, CardHeader, Typography, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import ManageSubGraph from './ManageSubGraph'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  legend: {
    display: 'flex',
    flexGrow: 1,
    maxWidth: 400,
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  description: {
    fontSize: '14px',
    color: theme.palette.grey[500],
  },
  overviewInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    padding: theme.spacing(3)
  },
  itemDisplay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .apexcharts-series path': {
      clipPath: 'inset(5% 5% 5% 0% round 8px)',
    },
  },
  infoDescription: {
    color: theme.palette.grey[500],
    fontSize: '12px',
    fontWeight: '500'
  },
  infoTitle: {
    color: '#587897',
    fontWeight: '700',
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px'
  },
  quarterlegend: {
    height: '12px',
    width: '12px',
    marginRight: theme.spacing(1),
    borderWidth: '0px',
    borderColor: 'rgb(255, 255, 255)',
    borderRadius: '12px',
  },

}))

export default function ManageOverviewCard({
  data
}) {
  const classes = useStyles()
  const { dictionary } = useTranslation()

  return (
    <Card className={classes.container}>
      <div className={classes.titleContainer}>
        <CardHeader
          title={
            <>
              <Box>{dictionary['management_dashboard']}</Box>
              <Typography className={classes.description}>
                {dictionary['trimester_details']}
              </Typography>
            </>
          }
        />
        <div className={classes.legend}>
          <div className={classes.legendContainer}>
            <span className={classes.quarterlegend} style={{ background: '#3483a2' }}></span>
            <span>{dictionary['q1']}</span>
          </div>
          <div className={classes.legendContainer}>
            <span className={classes.quarterlegend} style={{ background: '#6bc6b0' }}></span>
            <span>{dictionary['q2']}</span>
          </div>
          <div className={classes.legendContainer}>
            <span className={classes.quarterlegend} style={{ background: '#65a6d9' }}></span>
            <span>{dictionary['q3']}</span>
          </div>
          <div className={classes.legendContainer}>
            <span className={classes.quarterlegend} style={{ background: '#587897' }}></span>
            <span>{dictionary['q4']}</span>
          </div>
        </div>
      </div>
      <div className={classes.overviewInfoContainer}>
        <ManageSubGraph
          title={`${data ? numberWithSpaceInt(data.surface_area_managed) : 0}m2`}
          subtitle={dictionary['managed']}
          legend={dictionary['surface_managed']}
          graphData={data && data.surface_area_managed_trimesters}
        />
        <ManageSubGraph
          title={`${data ? numberWithSpaceInt(data.rent_managed) : 0}€`}
          subtitle={dictionary['of_rent']}
          legend={dictionary['rent_managed']}
          graphData={data && data.rent_managed_trimesters}
        />
        <ManageSubGraph
          title={`${data ? numberWithSpaceInt(data.lease_renewals) : 0}`}
          subtitle={dictionary['leases_renewed']}
          legend={dictionary['renewed']}
          graphData={data && data.lease_renewals_trimesters}
        />
        <ManageSubGraph
          title={`${data ? numberWithSpaceInt(data.lease_expirations) : 0}`}
          subtitle={dictionary['leases_ended']}
          legend={dictionary['lease_ending']}
          graphData={data && data.lease_expirations_trimesters}
        />
        <ManageSubGraph
          title={`${data ? numberWithSpaceInt(data.lease_signatures) : 0}`}
          subtitle={dictionary['signed']}
          legend={dictionary['signed_caps']}
          graphData={data && data.lease_signatures_trimesters}
        />
      </div>

    </Card>
  )
}
