import React from 'react'
import { useAuth } from '@contexts/auth'
import LandingPageView from '@components/landing'
import { ManagerDashboardView } from '@components/managerDashboard'

export default function Index() {
  const { isAuthenticated } = useAuth()

  return isAuthenticated ? <ManagerDashboardView /> : <LandingPageView />
}
