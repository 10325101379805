
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import dynamic from 'next/dynamic'
const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })
import { fNumber } from '@utils/formatNumber'


const useStyles = makeStyles((theme) => ({
  itemDisplay: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& .apexcharts-series path': {
      clipPath: 'inset(5% 5% 1% 0% round 8px)',
    },
  },
  infoDescription: {
    color: theme.palette.grey[500],
    fontSize: '12px',
    fontWeight: '500'
  },
  infoTitle: {
    color: '#587897',
    fontWeight: '700',
  },
  infoLegend: {
    color: theme.palette.primary.main,
    fontWeight: '700',
    fontSize: '14px'
  },
}))

export default function ManageSubGraph({
  title, subtitle, graphData, legend
}) {
  const classes = useStyles()
  let info = graphData && Object.values(graphData)

  // TODO -> refacto
  const transformInfoMinValue = (data) => {
    let newArray = []
    let maxValue = Math.max(...data)
    data.map(value => {
      if (value === 0) {
        newArray.push(maxValue / 100 * 1)
      }
      else {
        newArray.push(value)
      }
    })
    return newArray
  }

  const chartData = [{ data: info && info.length > 0 ? transformInfoMinValue(info) : [] }];

  const chartOptions = {
    colors: ['#3483a2', '#6bc6b0', "#65a6d9", "#587897"],
    chart: {
      sparkline: { enabled: true },
    },
    plotOptions: {
      bar: {
        columnWidth: '35%',
        distributed: true
      },
    },
    tooltip: {
      enabled: true,
      x: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: function (seriesName) {
            return '';
          }
        }
      },
      marker: { show: false },
    },
  }

  return (
    <div className={classes.itemDisplay}>
      <Typography className={classes.infoTitle}>
        {title}
      </Typography>
      <Typography className={classes.infoDescription}>
        {subtitle}
      </Typography>
      <div className={classes.chartStyle}>
        <ReactApexChart
          type="bar"
          series={chartData}
          options={chartOptions}
          width={100}
          height={120}
        />
        <Typography className={classes.infoLegend}>
          {legend}
        </Typography>
      </div>
    </div>
  )
}


