import React, { useState } from 'react';
import { useTranslation } from '@contexts/translation';
import { FormControlLabel, Checkbox, Typography, Box, Tooltip } from '@material-ui/core'
import { useFetchData } from '@api/fetchData'
import { Table, MyAvatar } from '@components/common'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { isBefore } from 'date-fns'
import { format } from 'date-fns'
import UnformatDate from '@utils/unformatDate'
import { MLabel } from '@theme/@material-extend';
import { onEditTask } from "@api/tasks";
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'



const useStyles = makeStyles((theme) => ({
  redText: {
    color: theme.palette.error.main,
  },
  description: {
    marginTop: theme.spacing(1),
    fontSize: '14px',
    color: theme.palette.grey[500],
  },
  titleDisplay: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '2',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  label: {
    fontSize: '11px',
    alignSelf: 'flex-start',
  }
}))

export default function ManagerTasksTable() {
  const { dictionary } = useTranslation()
  const [pageCount, setPageCount] = useState(1)
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  const fetchUrl = `/tasks/?page=${pageCount}&is_completed=false&is_logged=false`
  const { data, isLoading, isError } = useFetchData(
    fetchUrl,
    null,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  )

  const completeTask = async (checkedState, taskId) => {
    const updateTask = { is_completed: !checkedState }
    let response = await onEditTask(taskId, updateTask)
    if (response) {
      if (response.status === 200) {
        mutate(fetchUrl)
        enqueueSnackbar(dictionary['task_updated'], {
          variant: 'success',
        })
      }
    }
  };


  function getRandomColor() {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const columns = [
    {
      field: 'is_completed',
      headerName: dictionary['is_completed'],
      headerClassName: 'no_text',
      width: 50,
      sortable: false,
      renderCell: (params) => {
        return (
          <FormControlLabel
            control={<Checkbox checked={params.row.is_completed} onChange={() => completeTask(params.row.is_completed, params.row.id)} name="checked" />}
            label=""
          />
        )
      },
    },
    {
      field: 'title',
      sortable: false,
      headerName: dictionary['title'],
      minWidth: 130,
      flex: 1,
      renderCell: (params) => (
        <div className={classes.titleDisplay}>
          {params.row.title}
          {params.row.operation &&
            <MLabel
              className={classes.label}
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            >
              {params.row.operation.name}
            </MLabel>
          }
        </div>
      )
    },
    {
      field: 'due_date',
      sortable: false,
      width: 90,
      headerName: dictionary['date'],
      renderCell: (params) => {
        const isPastDate = params.row?.due_date
          ? isBefore(new Date(UnformatDate(params.row.due_date)), new Date())
          : false
        return (
          <MLabel
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={
              (isPastDate && !params.row.is_completed ? 'error' : 'success')
            }
          >
            {params.row.due_date ? format(new Date(UnformatDate(params.row.due_date)), 'd LLL yy') : '-'}
          </MLabel>

        )
      },
    },
    {
      field: 'assigned_to',
      sortable: false,
      headerName: dictionary['owner'],
      headerClassName: 'round_corner',
      minWidth: 45,
      flex: 1,
      renderCell: (params) => (
        <Tooltip placement="top" title={params.row.assigned_to && params.row.assigned_to.display}>
          <span>
            <MyAvatar
              sx={{
                mx: '0',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: 'common.white',
                width: { xs: 40, md: 40 },
                height: { xs: 40, md: 40 },
              }}
              name={params.row.assigned_to && params.row.assigned_to.display}
            />
          </span>
        </Tooltip>
      )
    },
  ]


  return (
    <Table
      loading={isLoading}
      rows={data && data.results ? data.results : []}
      page={pageCount}
      isLightTable={true}
      handlePageChange={handlePageChange}
      count={data && data.count}
      disableColumnSelector={true}
      disableColumnMenu={true}
      columns={columns}
      title={
        <>
          <Box>{dictionary["tasks"]}</Box>
          <Typography className={classes.description}>
            {dictionary['task_order']}
          </Typography>
        </>
      }
    />
  );
}
