import client from '@utils/client'

export const updateBudgets = (payload, id) => {
  return client
    .patch(`/budgets/year/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}


export const onGetCategoriesByOperation = (id) => {
  return client
    .get(`/categories/?operation=${id}&page_size=9999`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const onGetCategories = () => {
  return client
    .get(`/categories/?page_size=9999`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}
