import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Box, Card, CardContent } from '@material-ui/core';
import { useTranslation } from '@contexts/translation';
import { format } from 'date-fns'
import { enUS, fr } from 'date-fns/locale'
import { useFetchData } from '@api/fetchData'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: '#e2eaff',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      textAlign: 'left',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('xl')]: {
      height: 320
    }
  },
  link: {
    color: theme.palette.primary.main
  },
}));

function WelcomeCard({ displayName, className, ...other }) {
  const classes = useStyles();
  const { dictionary, lang } = useTranslation()
  const today = new Date()

  const { data, isLoading, isError } = useFetchData(
    `/profiles/user/`,
    null,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  )


  return (
    <Card className={clsx(classes.root, className)} {...other}>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: '#587896',
            textTransform: "capitalize",
            pb: { xs: 3, xl: 5 }
          }}
        >
          {format(today, 'EEEE d LLLL', { locale: lang === 'fr' ? fr : enUS })}
        </Typography>

        <Typography
          variant="h1"
          sx={{
            color: 'grey.800',
            pb: { xs: 3, xl: 5 }
          }}
        >
          {dictionary['welcome']} {data && data.first_name && data.first_name} !
        </Typography>

      </CardContent>

      <Box
        component="img"
        alt="welcome"
        src="/static/images/Summary.png"
        sx={{
          p: 2,
          height: 300,
          margin: { xs: 'auto', md: 'inherit' }
        }}
      />
    </Card>
  );
}

export default WelcomeCard;
