import React, { useState } from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Table } from '@components/common'
import { useFetchData } from '@api/fetchData'
import { Typography, Box } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GridOverlay } from '@material-ui/data-grid';
import { numberWithSpaces } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  description: {
    fontSize: '14px',
    color: theme.palette.grey[500],
  },
  icon: {
    width: 170,
    height: 'auto',
  },
  balanceTotal: {
    float: 'right',
  },

}))

export default function ManagerRecapTable({
  title,
  color,
  fetchUrl,
  columns,
  imgSrc,
}) {
  const theme = useTheme()
  const [pageCount, setPageCount] = useState(1)
  const classes = useStyles();

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }


  const { data, isLoading, isError } = useFetchData(fetchUrl, null, { revalidateOnFocus: false, revalidateOnReconnect: false })

  let displayData = data && data.results && data.results.length > 0 && data.results.filter(info => info.balance > 0).sort((a, b) => b.balance - a.balance)

  let total = data && data.results && data.results.length > 0 && data.results.reduce((total, obj) => obj.balance + total,0)

  function CustomNoRowsOverlay() {
    return (
      <GridOverlay>
        <img src={imgSrc} className={classes.icon} />
      </GridOverlay>
    );
  }
  return (
    <Table
      columns={columns}
      loading={isLoading}
      rows={data && data.results && data.results.length > 0 ? displayData : []}
      page={pageCount}
      color={color}
      headerHeight={32}
      isLightTable={true}
      fixedHeight='220px'
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      style={{ padding: theme.spacing(1) }}
      handlePageChange={handlePageChange}
      count={displayData && displayData.length}
      disableColumnSelector={true}
      disableColumnMenu={true}
      title={
        <Box>{title} <span className={classes.balanceTotal}>{numberWithSpaces(total)}€</span></Box>
      }
    />
  )
}
