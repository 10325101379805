import React from 'react'
import Link from 'next/link'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import { Card, Typography } from '@material-ui/core'
import { MLabel } from '@theme/@material-extend'
import HouseIcon from '@material-ui/icons/House'
import ApartmentIcon from '@material-ui/icons/Apartment'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import HotelIcon from '@material-ui/icons/Hotel'
const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    padding: theme.spacing(2, 1, 2, 4),
  },
  lotsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.7fr',
    gap: theme.spacing(4, 1),
    gridTemplateAreas: `
      "operations lotsType"
      "lots lotsDetails"
    `
  },
  overviewInfoContainer: {
    gridArea: 'operations',
    display: 'flex',
    flexDirection: 'column',
  },
  lotsDetailsContainer: {
    gridArea: 'lotsType',
    display: 'flex',
    flexDirection: 'column',
  },
  lotsTypeList: {
    listStyle: 'none',
    '& li': {
      display: 'flex',
      fontSize: '14px',
      fontWeight: '100',
      alignItems: 'center',
      paddingBottom: theme.spacing(1)
    },
    '& li:last-child': {
      paddingBottom: 0,
    },
  },
  listIcon: {
    marginRight: theme.spacing(1)
  },
  lotsNumber: {
    color: theme.palette.grey[500],
    fontWeight: '300',
  },
  number: {
    fontWeight: '700',
  },
  lotsLabel: {
    display: 'grid',
    gridArea: 'lotsDetails',
    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))',
    gridAutoRows: '20px',
    gridGap: '10px',
  },
  label: {
    fontWeight: '500',
    justifyContent: 'flex-start',
    color: theme.palette.primary.main
  }
}))

export default function LotsOverviewCard({
  data
}) {
  const classes = useStyles()
  const { dictionary } = useTranslation()

  return (
    <Card className={classes.container}>
      <div className={classes.lotsContainer}>
        <div className={classes.overviewInfoContainer}>
          <Typography variant="h1">
            {data && data.operation_count}
          </Typography>
          <Link href="/operations" passHref>
            <Typography
              variant="body1"
              sx={{
                cursor: "pointer"
              }}
            >
              {dictionary['operations']}
            </Typography>
          </Link>
        </div>
        <div style={{ gridArea: 'lots' }} >
          <Typography className={classes.lotsNumber}>
            <span className={classes.number}>{data && data.lot_count}</span> {dictionary['lots']}
          </Typography>
        </div>
        <div className={classes.lotsDetailsContainer}>
          <ul className={classes.lotsTypeList}>
            <li><ApartmentIcon className={classes.listIcon} />{data && data.commercial_count} {dictionary['office_lot']}</li>
            <li><HomeWorkIcon className={classes.listIcon} /> {data && data.residential_count} {dictionary['residential_home']}</li>
            <li><HotelIcon className={classes.listIcon} />{data && data.apartment_count} {dictionary['apartment']}</li>
            <li><HouseIcon className={classes.listIcon} /> {data && data.house_count} {dictionary['residential_lot']} </li>
          </ul>
        </div>
        <div className={classes.lotsLabel}>
          <MLabel
            className={classes.label}
            color='secondary'
          >
            {data && data.office_lot_count} {dictionary['offices']}
          </MLabel>
          <MLabel
            color='secondary'
            className={classes.label}
          >
            {data && data.parking_count} {dictionary['parkings']}
          </MLabel>
          <MLabel
            className={classes.label}
            color='primary'
          >
            {data && data.storage_count} {dictionary['cellar']}
          </MLabel>
          <MLabel
            color='warning'
            className={classes.label}
          >
            {data && data.residential_lot_count} {dictionary['houses']}
          </MLabel>
        </div>
      </div>
    </Card>
  )
}
