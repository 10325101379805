import React from 'react'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from '@contexts/translation'
import { Card, Box, CardHeader, Typography } from '@material-ui/core'
import { numberWithSpaceInt } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  rentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  rentPerMonth: {
    color: '#499a5b'
  },
  number: {
    fontWeight: '700',
    fontSize: '1.5rem',
  },
  rentAverage: {
    fontSize: '12px',
    color: theme.palette.grey[500],
  },
  bar: {
    position: 'relative',
    width: '100%',
    borderRadius: '50rem',
    color: 'white',
    backgroundColor: 'rgb(73 154 91 / 20%)',
    margin: theme.spacing(2, 0),
    '& p': {
      paddingLeft: theme.spacing(2)
    },
  },
  barProgress: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'inline-block',
    height: '100%',
    borderRadius: '50rem',
    zIndex: '-1'
  },
  potential: {
    color: theme.palette.grey[500],
  },
}))

export default function RentOverviewCard({
  data
}) {
  const classes = useStyles()
  const theme = useTheme()
  const { dictionary } = useTranslation()

  const percentage = data && Math.round((data.rent_year_actual * 100 / data.rent_year_potential) * 100) / 100
  return (
    <Card className={classes.container}>
      <CardHeader
        title={
          <Box>{dictionary['my_rents']}</Box>
        } />
      <div className={classes.rentContainer}>
        <Typography className={classes.rentPerMonth}>
          <span className={classes.number}>{data && numberWithSpaceInt(data.rent_year_actual)}</span>€/{dictionary['year_fr']}
        </Typography>
        {/* <Typography className={classes.rentAverage}>
          {dictionary['average_rent']} 34 000€/{dictionary['month']}
        </Typography> */}
        <div className={classes.bar}>
          <p>{percentage ? percentage : '0'}%</p>
          <span
            className={classes.barProgress}
            style={{
              minWidth: '70px',
              width: `${percentage ? percentage : '0'}%`,
              background: '#499a5b',
            }}
          />
        </div>
        <Typography className={classes.potential}>
          {dictionary['potential_of']} {data && numberWithSpaceInt(data.rent_year_potential)}€/{dictionary['year_fr']}
        </Typography>
      </div>
    </Card>
  )
}
